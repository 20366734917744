import { get, toInteger, toString } from "lodash/fp";
import { FieldMap } from "../types/field-map";
import { ICustomer, IEstimate, IJobOrder } from "../types";
import { parseData as parseEstimate } from "../transformers/estimate";
import { parseData as parseSchedule } from "../transformers/schedule";
import dayjs from "dayjs";

const FIELD_MAPPING: FieldMap[] = [
  {
    key: "id",
    field: "id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "customerId",
    field: "customer_id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "advisorId",
    field: "advisor_id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "estimateId",
    field: "estimate_id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "shopVehicleId",
    field: "shop_vehicle_id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "shopId",
    field: "shop_id",
    transform: { response: (value: any) => toInteger(value) },
    hidden: true,
  },
  {
    key: "referenceNo",
    field: "reference_no",
    transform: { response: (value: any) => toString(value) },
  },
  {
    key: "customerName",
    field: "customers",
    render: (customer: ICustomer) => "John Doe",
    exclude: true,
  },
  {
    key: "overview",
    field: "estimate",
    transform: { response: (value: any) => parseEstimate(value) },
    render: (value: any) => `${value.vehicleDetails.mileage} Mileage`,
    exclude: true,
  },
  {
    key: "status",
    field: "latest_status",
    transform: { response: (value: any) => toString(value) },
    render: (value: any) => toString(value),
  },
  {
    key: "amount",
    field: "estimate",
    transform: { response: (value: any) => parseEstimate(value) },
    render: (value: any) => `₱${value.grandTotal}`,
    exclude: true,
  },
  {
    key: "appointmentSchedule",
    field: "estimate",
    transform: { response: (value: any) => parseEstimate(value) },
    render: (value: any) =>
      dayjs(`${value.startDate}T${value.startTime}`).format(
        "DD MMMM YYYY h:mm A"
      ),
    exclude: true,
  },
  {
    key: "schedule",
    field: "schedule",
    transform: { response: (value: any) => parseSchedule(value) },
    hidden: true,
    exclude: true,
  },
];

export const parseData = (json: any): IJobOrder => {
  return FIELD_MAPPING.reduce((acc: IJobOrder, curr: FieldMap) => {
    const { key, field, transform } = curr;
    const path = typeof field === "string" ? field : field?.response;
    const raw = get(path, json);
    const formatted = transform?.response ? transform?.response(raw) : raw;
    return { ...acc, [key]: formatted };
  }, {} as IJobOrder);
};

export const parsePayload = (json: any) => {
  return FIELD_MAPPING.reduce((acc, curr: FieldMap) => {
    const { key, field, transform, exclude } = curr;
    const value = get(key, json);
    if (exclude) return acc;
    return {
      ...acc,
      ...(typeof value != "undefined" && {
        [typeof field === "string" ? field : field.payload]: transform?.payload
          ? transform.payload(value)
          : value,
      }),
    };
  }, {});
};

export const keys = FIELD_MAPPING.filter(({ hidden }) => !hidden).map(
  (field) => ({
    column: field.key,
    path: field.path || field.key,
    render: field.render,
  })
);
